










import { Component, Prop, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalCallAttention extends Mixins(ModalBaseMethod) {
  @Prop()
  alerts!: []

  private transitionToTarget(): void {
    // TODO: 実際は該当ユーザにページ内リンク。現状モック用指定。
    this.hide()
  }
}
