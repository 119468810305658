


















import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ModalCallAttention from '@/components/organisms/ModalCallAttention.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ColoredBox,
    ModalCallAttention,
    ButtonBase,
  },
})
export default class CallAttention extends Vue {
  @Prop()
  attentionData!: {}

  @Prop()
  onClickSupporterCall!: any

  @Ref() modalCallAttention!: ModalCallAttention

  private showCallAttentionList(): void {
    this.modalCallAttention.show()
  }

  private transitionToTarget(): void {
    // TODO: 実際は該当ユーザにページ内リンク。現状モック用指定。
  }
}
